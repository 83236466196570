import http from '~/utils/http'

/** 获取所有套餐 */
export function getAppointment(page: number, size: number) {
  return http.get('/appointment', {
    params: {
      page,
      size,
    },
  })
}

/** 取消预约 */
export function cancelAppointment(id: number) {
  return http.delete(`/appointment/${id}/teacher-cancel`)
}

export function getAppointmentDetail(id: number) {
  return http.get(`/appointment/${id}`)
}
