import { Button, Menu, MenuProps } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import menus from '~/config/menus'
import { TOKEN_KEY } from '~/constants/storage'

import { Container } from './style'

const Index = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const onSelect = useCallback<Required<MenuProps>['onClick']>((menuInfo) => {
    navigate(menuInfo.key)
  }, [])

  const [selectedKeys, setSelectedKeys] = useState<string[]>(['overview'])

  useEffect(() => {
    setSelectedKeys([location.pathname])
  }, [location])

  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([])

  const isFirst = useRef(true)
  if (isFirst.current) {
    isFirst.current = false
    menus.forEach((menu) => {
      if (menu.children && menu.children?.length > 0) {
        menu.children.forEach((child) => {
          if (child.key === location.pathname) {
            setDefaultOpenKeys([menu.key])
          }
        })
      }
    })
  }
  const logout = useCallback(() => {
    localStorage.removeItem(TOKEN_KEY)
    navigate('/login')
  }, [])

  return (
    <Container>
      <div className="top">
        <div className="title">Shaokai-Chasing Tutor</div>
        <div className="right">
          <Button className="button" type="text" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="menu">
            <Menu
              defaultOpenKeys={defaultOpenKeys}
              selectedKeys={selectedKeys}
              items={menus}
              mode="inline"
              onClick={onSelect}
            />
          </div>
        </div>
        <div className="right">
          <div className="content">
            <Outlet />
          </div>
          <div className="copyright">Shaokai Copyright © 2024</div>
        </div>
      </div>
    </Container>
  )
}

export default Index
