import TweenOne from 'rc-tween-one'
import React from 'react'
import { JSX } from 'react/jsx-runtime'

class GridLayout {
  gridX: number
  gridY: number
  cellWidth: number
  cellHeight: number
  grid: any[]
  constructor(rect: number, width: number, height: number) {
    this.gridX = Math.floor(width / rect)
    this.gridY = Math.floor(height / rect)
    this.cellWidth = width / this.gridX
    this.cellHeight = height / this.gridY
    this.grid = []
    for (let i = 0; i < this.gridY; i += 1) {
      this.grid[i] = []
      for (let s = 0; s < this.gridX; s += 1) {
        this.grid[i][s] = []
      }
    }
  }

  getCells = (e: { x: number; radius: number; y: number }) => {
    const gridArray = []
    const w1 = Math.floor((e.x - e.radius) / this.cellWidth)
    const w2 = Math.ceil((e.x + e.radius) / this.cellWidth)
    const h1 = Math.floor((e.y - e.radius) / this.cellHeight)
    const h2 = Math.ceil((e.y + e.radius) / this.cellHeight)
    for (let c = h1; c < h2; c += 1) {
      for (let l = w1; l < w2; l += 1) {
        gridArray.push(this.grid[c][l])
      }
    }
    return gridArray
  }

  hasCollisions = (t: { x: number; y: number; radius: number }) =>
    this.getCells(t).some((e) => e.some((v: any) => this.collides(t, v)))

  collides = (
    t: { x: number; y: number; radius: any },
    a: { x: number; y: number; radius: any },
  ) => {
    if (t === a) {
      return false
    }
    const n = t.x - a.x
    const i = t.y - a.y
    const r = t.radius + a.radius
    return n * n + i * i < r * r
  }

  add = (value: { x: number; y: number; radius: number }) => {
    this.getCells(value).forEach((item) => {
      item.push(value)
    })
  }
}

const getPointPos = (width: number, height: number, length: number) => {
  const grid = new GridLayout(150, width, height)
  const posArray = []
  const num = 500
  const radiusArray = [20, 35, 60]
  for (let i = 0; i < length; i += 1) {
    let radius
    let pos
    let j = 0
    for (let j = 0; j < num; j += 1) {
      radius = radiusArray[Math.floor(Math.random() * radiusArray.length)]
      pos = {
        x: Math.random() * (width - radius * 2) + radius,
        y: Math.random() * (height - radius * 2) + radius,
        radius,
      }
      if (!grid.hasCollisions(pos)) {
        break
      }
    }
    posArray.push(pos)
    grid.add(pos!)
  }
  return posArray
}

const getDistance = (t: { x: any; y: any }, a: { x: any; y: any }) =>
  Math.sqrt((t.x - a.x) * (t.x - a.x) + (t.y - a.y) * (t.y - a.y))

class Point extends React.PureComponent {
  render() {
    const { tx, ty, x, y, opacity, backgroundColor, radius, ...props } = this
      .props as any
    let transform
    let zIndex = 0
    let animation = {
      y: (Math.random() * 2 - 1) * 20 || 15,
      duration: 3000,
      delay: Math.random() * 1000,
      yoyo: true,
      repeat: -1,
    }
    if (tx && ty) {
      if (tx !== x && ty !== y) {
        const distance = getDistance({ x, y }, { x: tx, y: ty })
        const g = Math.sqrt(2000000 / (0.1 * distance * distance))
        transform = `translate(${(g * (x - tx)) / distance}px, ${(g * (y - ty)) / distance}px)`
      } else if (tx === x && ty === y) {
        transform = `scale(${80 / radius})`
        animation = { y: 0, yoyo: false, repeat: 0, duration: 300 } as any
        zIndex = 1
      }
    }
    return (
      <div
        style={{
          left: x - radius,
          top: y - radius,
          width: radius * 1.8,
          height: radius * 1.8,
          // backgroundColor: `rgba(0,0,0,${opacity})`,
          opacity: 0.5,
          zIndex,
          transform,
        }}
        {...props}
      >
        <TweenOne
          animation={animation}
          style={{
            backgroundColor,
          }}
          // @ts-ignore
          className={`${this.props.className}-child`}
        />
      </div>
    )
  }
}

class LinkedAnimate extends React.Component {
  static defaultProps = {
    className: 'linked-animate-demo',
  }

  num = 50 // 点的个数
  box: any

  constructor(props: {}) {
    super(props)
    this.state = {
      data: getPointPos(1280, 600, this.num).map((item) => ({
        ...item,
        opacity: Math.random() * 0.2 + 0.05,
        backgroundColor: `rgb(${Math.round(Math.random() * 21 + 0)},19,255, 0.25)`,
      })),
      tx: 0,
      ty: 0,
    }
  }

  onMouseMove = (e: { clientX: any; clientY: any }) => {
    const cX = e.clientX
    const cY = e.clientY
    const boxRect = this.box.getBoundingClientRect()
    // @ts-ignore
    const pos = this.state.data
      .map((item: { x: any; y: any; radius: any }) => {
        const { x, y, radius } = item
        return {
          x,
          y,
          distance:
            getDistance({ x: cX - boxRect.x, y: cY - boxRect.y }, { x, y }) -
            radius,
        }
      })
      .reduce((a: { distance: number }, b: { distance: number }) => {
        if (!a.distance || a.distance > b.distance) {
          return b
        }
        return a
      })
    if (pos.distance < 60) {
      this.setState({
        tx: pos.x,
        ty: pos.y,
      })
    } else {
      this.onMouseLeave()
    }
  }

  onMouseLeave = () => {
    this.setState({
      tx: 0,
      ty: 0,
    })
  }

  render() {
    // @ts-ignore
    const { className } = this.props
    // @ts-ignore
    const { data, tx, ty } = this.state
    return (
      <div className={`${className}-wrapper`}>
        <div
          className={`${className}-box`}
          ref={(c) => {
            this.box = c
          }}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
        >
          {data.map(
            (
              item: JSX.IntrinsicAttributes &
                JSX.IntrinsicClassAttributes<Point> &
                Readonly<{}>,
              i: { toString: () => React.Key | null | undefined },
            ) => (
              <Point
                {...item}
                // @ts-ignore
                tx={tx}
                ty={ty}
                key={i.toString()}
                className={`${className}-block`}
              />
            ),
          )}
        </div>
      </div>
    )
  }
}

export default LinkedAnimate
