import { SmileOutlined } from '@ant-design/icons'

import { Container } from './style'

const Index = () => {
  return (
    <Container>
      <div className="welcome">Welcome~</div>
      <SmileOutlined style={{ color: '#333', fontSize: 30 }} />
    </Container>
  )
}

export default Index
