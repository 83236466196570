import {
  FastboardApp,
  InsertDocsParams,
  SceneDefinition,
  createFastboard,
  createUI,
} from '@netless/fastboard'
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalAudioTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng'
import { Button, Tooltip, message } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BOARD_APP_ID, RTC_APP_ID } from '~/constants/agora'
import { getBoardRoom } from '~/services/agora'
import { getAppointmentDetail } from '~/services/appointment'
import { getClassroomTime, patchClassroomTime } from '~/services/classroom'
import { getTextbook } from '~/services/textbook'
import { ITextbook } from '~/types/textbook'

import AudioOffSvg from './audio-off.svg'
import AudioSvg from './audio.svg'
import ScreenSvg from './screen.svg'
import { Container } from './style'
import VideoOffSvg from './video-off.svg'
import VideoSvg from './video.svg'

const Index = () => {
  const [teacherName, setTeacherName] = useState('')
  const [studentName, setStudentName] = useState('')
  const audioTrackRef = useRef<IMicrophoneAudioTrack>()
  const videoTrackRef = useRef<ICameraVideoTrack>()
  const screenRef = useRef<
    [ILocalVideoTrack, ILocalAudioTrack] | ILocalVideoTrack
  >()
  const userUuidRef = useRef('')
  const rtcTokenRef = useRef('')
  const roomUuidRef = useRef('')
  const rtcScreenTokenRef = useRef('')
  const rtcScreenUidRef = useRef('')

  const clientRef = useRef<IAgoraRTCClient>()
  const screenClientRef = useRef<IAgoraRTCClient>()

  const [isVideoOn, setIsVideoOn] = useState(false)
  const [isAudioOn, setIsAudioOn] = useState(false)

  /** 打开摄像头 */
  const turnOnCamera = useCallback(async (flag: boolean) => {
    setIsVideoOn(flag)

    if (videoTrackRef.current) {
      return videoTrackRef.current?.setEnabled(flag)
    }
    videoTrackRef.current = await AgoraRTC.createCameraVideoTrack()
    videoTrackRef.current.play('camera-video')
    clientRef.current?.publish(videoTrackRef.current)
  }, [])

  /** 打开话筒 */
  const turnOnAudio = useCallback(async (flag: boolean) => {
    setIsAudioOn(flag)

    if (audioTrackRef.current) {
      return audioTrackRef.current.setEnabled(flag)
    }
    audioTrackRef.current = await AgoraRTC.createMicrophoneAudioTrack()
    clientRef.current?.publish(audioTrackRef.current)
  }, [])

  /** 打开屏幕分享 */
  const turnOnScreenVideo = useCallback(async () => {
    screenRef.current = await AgoraRTC.createScreenVideoTrack(
      {
        // 配置屏幕共享编码参数，详情请查看 API 文档
        encoderConfig: '1080p_2',
        // 设置视频传输优化策略为清晰优先或流畅优先
        optimizationMode: 'detail',
      },
      'auto',
    )
    if (!(screenRef.current instanceof Array)) {
      const screenRefCurrent = screenRef.current as ILocalVideoTrack
      screenRefCurrent.on('track-ended', () => {
        screenClientRef.current?.unpublish(screenRefCurrent)
        screenRefCurrent.close()
      })
    } else {
      const screenRefCurrent = screenRef.current as [
        ILocalVideoTrack,
        ILocalAudioTrack,
      ]

      screenRefCurrent[0].on('track-ended', () => {
        const screenRefCurrent = screenRef.current
        screenClientRef.current?.unpublish(screenRefCurrent)
      })
    }

    if (screenClientRef.current) {
      await screenClientRef.current.publish(screenRef.current)
    }
  }, [])

  /** 用户订阅 */
  const onUserPublish = useCallback(
    async (user: IAgoraRTCRemoteUser, mediaType: 'video' | 'audio') => {
      if (
        user.uid !== userUuidRef.current &&
        user.uid !== rtcScreenUidRef.current &&
        clientRef.current
      ) {
        if (mediaType === 'video') {
          const remoteTrack = await clientRef.current.subscribe(user, mediaType)
          remoteTrack.play('remote-video')
        }
        if (mediaType === 'audio') {
          const remoteTrack = await clientRef.current.subscribe(user, mediaType)
          remoteTrack.play()
        }
      }
    },
    [],
  )
  const params = useParams<{ id: string }>()
  const intervalRef = useRef<NodeJS.Timeout>()
  const [isTimeout, setIsTimeout] = useState(false)
  const [classTiktokTime, setClassTiktokTime] = useState(0)
  const startTikTok = useCallback((time: number) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    setClassTiktokTime(+dayjs() - time)
    intervalRef.current = setInterval(() => {
      const date = +dayjs() - time
      if (date >= 25 * 60 * 1000) {
        setIsTimeout(true)
      }
      setClassTiktokTime(date)
    }, 1000)
  }, [])

  const queryTiktokTime = useCallback(
    (id: number) => {
      /** 查询房间进行时间 */
      getClassroomTime(id)
        .then((res) => {
          const classStartedAt = res.data.classStartedAt
          if (classStartedAt) {
            startTikTok(classStartedAt)
          }
        })
        .catch(() => {
          // message.error(err.response?.data || 'error')
        })
    },
    [startTikTok],
  )
  const classTiktokTimeText = useMemo(() => {
    if (classTiktokTime) {
      const sec = `0${Math.floor((classTiktokTime / 1000) % 60)}`.slice(-2)
      const min = Math.floor(classTiktokTime / 1000 / 60)

      return `Started: ${min}:${sec}`
    } else {
      return '--:--'
    }
  }, [classTiktokTime])

  useEffect(() => {
    const id = Number(params.id)
    if (id) {
      queryTiktokTime(id)
    }
  }, [params.id, queryTiktokTime])

  const textbookDataRef = useRef<ITextbook>()

  /** 打开课本 */
  const openTextbook = useCallback((boardApp: FastboardApp) => {
    if (textbookDataRef.current) {
      const downloadUrl = textbookDataRef.current.downloadUrl
      const conversionResult = textbookDataRef.current.conversionResult
      const dotIndex = downloadUrl.lastIndexOf('.')
      const fileType = downloadUrl.slice(dotIndex + 1)

      if (fileType === 'pdf') {
        const scenes: SceneDefinition[] = Object.keys(
          conversionResult.images,
        ).map((item: any) => {
          const image = conversionResult.images[item]
          return {
            name: item,
            ppt: {
              width: image.width,
              height: image.height,
              src: image.url,
            },
          }
        })
        boardApp.insertDocs({
          fileType,
          scenePath: `/${fileType}/${conversionResult.taskId}`,
          scenes,
          title: `${textbookDataRef.current.title} - ${textbookDataRef.current.unit} - ${textbookDataRef.current.chapter}`,
        } as InsertDocsParams)
      }
      if (fileType === 'pptx') {
        boardApp.insertDocs({
          fileType,
          scenePath: `/${fileType}/${conversionResult.taskId}`,
          taskId: conversionResult.taskId,
          title: `${textbookDataRef.current.title} - ${textbookDataRef.current.unit} - ${textbookDataRef.current.chapter}`,
          // url: textbookDataRef.current.conversionResult.prefixUrl,
          url: 'https://resource.shaokai-chasing.com/shengwang/online-textbook/dynamicConvert',
        })
      }
    }
  }, [])

  const initBoard = useCallback(async () => {
    const id = Number(params.id)
    if (id) {
      try {
        const res = await getBoardRoom(id)
        if (res.data.boardUuid) {
          const boardApp = await createFastboard({
            sdkConfig: {
              appIdentifier: BOARD_APP_ID,
              region: 'cn-hz',
            },
            joinRoom: {
              uid: `${userUuidRef.current}`,
              uuid: res.data.boardUuid,
              roomToken: res.data.boardRoomToken,
            },
            managerConfig: {
              overwriteStyles:
                '.netless-window-manager-wrapper { background: #fff }',
            },
          })
          const moundDiv = document.getElementById('left-area')
          if (moundDiv) {
            createUI(boardApp, moundDiv)
            openTextbook(boardApp)
          }
          // boardApp.manager.appManager
        } else {
          setTimeout(() => {
            initBoard()
          }, 2000)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }, [openTextbook, params.id])

  const queryTextbook = useCallback(
    (id: number) => {
      getTextbook(id)
        .then((res) => {
          console.log('教材', res)
          textbookDataRef.current = res.data
          initBoard()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    [initBoard],
  )

  const initRTC = useCallback(() => {
    const id = Number(params.id)
    if (id) {
      /** 获取房间信息 */
      getAppointmentDetail(id)
        .then((res) => {
          queryTextbook(res.data.textbookId)

          roomUuidRef.current = res.data.roomUuid
          userUuidRef.current = res.data.teacher.userId
          rtcTokenRef.current = res.data.rtcToken

          rtcScreenTokenRef.current = res.data.rtcScreenToken

          rtcScreenUidRef.current = res.data.rtcScreenUid
          const teacherName = res.data.teacher.name
          const studentName = res.data.student.name
          setTeacherName(teacherName)
          setStudentName(studentName)

          if (userUuidRef.current && rtcTokenRef.current) {
            clientRef.current = AgoraRTC.createClient({
              mode: 'rtc',
              codec: 'vp8',
            })
            clientRef.current.on('user-published', onUserPublish)

            clientRef.current
              .join(
                RTC_APP_ID,
                roomUuidRef.current,
                rtcTokenRef.current,
                userUuidRef.current,
              )
              .then(() => {
                turnOnCamera(true)
                turnOnAudio(true)
              })

            screenClientRef.current = AgoraRTC.createClient({
              mode: 'rtc',
              codec: 'vp8',
            })
            screenClientRef.current.join(
              RTC_APP_ID,
              roomUuidRef.current,
              rtcScreenTokenRef.current,
              rtcScreenUidRef.current,
            )
          }
        })
        .catch((err) => {
          console.error(err)
          message.error(err.response?.data || 'error')
        })
    }
  }, [onUserPublish, params.id, queryTextbook, turnOnAudio, turnOnCamera])

  useEffect(() => {
    initRTC()
  }, [initRTC])

  return (
    <Container>
      <div className="top">
        <div className="title">
          <div className="title-text">{`${teacherName} 's room`} </div>
        </div>
        <div className="top-middle">
          <div className={`time ${isTimeout ? 'timeout' : ''}`}>
            {classTiktokTimeText}
          </div>
          {!classTiktokTime && (
            <Button
              className="btn"
              size="small"
              type="primary"
              shape="round"
              onClick={() => {
                patchClassroomTime(Number(params.id))
                  .then(() => {
                    startTikTok(+dayjs())
                  })
                  .catch((err) => {
                    message.error(err.response?.data)
                  })
              }}
            >
              Start Class
            </Button>
          )}
        </div>
        <div className="top-right">
          <Tooltip title="ScreenShare">
            <div className="icon" onClick={turnOnScreenVideo}>
              <ScreenSvg />
            </div>
          </Tooltip>
          <Tooltip title="Toggle Audio">
            <div
              className="icon"
              onClick={() => {
                turnOnAudio(!isAudioOn)
              }}
            >
              {isAudioOn ? <AudioSvg /> : <AudioOffSvg />}
            </div>
          </Tooltip>
          <Tooltip title="Toggle Video">
            <div
              className="icon"
              onClick={() => {
                turnOnCamera(!isVideoOn)
              }}
            >
              {isVideoOn ? <VideoSvg /> : <VideoOffSvg />}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="bottom">
        <div className="left" id="left-area"></div>
        <div className="right">
          <div id="camera-video" className="video-wrap local">
            <div className="mask">{teacherName}</div>
          </div>
          <div id="remote-video" className="video-wrap remote">
            <div className="mask">{studentName}</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Index
