import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  > .content {
    height: 100%;
    display: flex;
    padding-left: 12px;
    > .left {
      min-width: 400px;
      width: 400px;
      flex: 0 1 auto;
      align-self: center;
    }
    > .divider {
      width: 1px;
      background-color: #ddd;
      margin: 0 12px;
    }
  }
`

export const RightContainer = styled.div`
  flex: 1;
  padding: 24px 0 0 12px;
  overflow-y: auto;
  position: relative;
  > .loading-wrap {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  > .empty {
    margin-top: 30px;
  }
  > .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    > .card {
      border: 1px solid #ddd;
      padding: 12px;
      margin-bottom: 12px;
      margin-right: 12px;
      border-radius: 8px;
      > .line {
        display: flex;
        align-items: center;
        margin-top: 12px;
        > .label {
          margin-right: 12px;
          width: 60px;
          font-size: 14px;
        }
      }
      > .del-button {
        margin-top: 24px;
        text-align: right;
      }
    }
  }
`
