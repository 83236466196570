import { createFastboard, createUI } from '@netless/fastboard'
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from 'agora-rtc-sdk-ng'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'

import { BOARD_APP_ID, RTC_APP_ID } from '~/constants/agora'
import { getBoardRecordRoom } from '~/services/agora'
import {
  getClassroomTime,
  getRecordClassroomDetail,
} from '~/services/classroom'

import { Container } from './style'

const Index = () => {
  const params = useParams<{ id: string }>()
  const [teacherName, setTeacherName] = useState('')
  const [studentName, setStudentName] = useState('')
  const teacherUserIdRef = useRef('')
  const studentUserIdRef = useRef('')
  const screenUidRef = useRef('')

  const clientRef = useRef<IAgoraRTCClient>()

  const onUserPublish = useCallback(
    async (user: IAgoraRTCRemoteUser, mediaType: 'video' | 'audio') => {
      if (clientRef.current) {
        if (mediaType === 'video') {
          const remoteTrack = await clientRef.current.subscribe(user, mediaType)
          if (user.uid === teacherUserIdRef.current) {
            remoteTrack.play('camera-video')
          } else if (user.uid === screenUidRef.current) {
            remoteTrack.play('screen-video')
          } else if (user.uid === studentUserIdRef.current) {
            remoteTrack.play('remote-video', {
              fit: 'contain',
            })
          }
        }
        if (mediaType === 'audio') {
          const remoteTrack = await clientRef.current.subscribe(user, mediaType)
          remoteTrack.play()
        }
      }
    },
    [],
  )
  useEffect(() => {
    if (Number(params.id))
      getRecordClassroomDetail(Number(params.id))
        .then((res) => {
          teacherUserIdRef.current = res.data.teacherUserId
          studentUserIdRef.current = res.data.studentUserId
          screenUidRef.current = res.data.screenUid
          setTeacherName(res.data.teacherName)
          setStudentName(res.data.studentName)
          clientRef.current = AgoraRTC.createClient({
            mode: 'rtc',
            codec: 'vp8',
          })
          clientRef.current.on('user-published', onUserPublish)

          clientRef.current
            .join(RTC_APP_ID, res.data.roomUuid, res.data.token, res.data.uid)
            .then(() => {})
        })
        .catch(() => {
          //
        })
  }, [onUserPublish, params.id])

  const initBoard = useCallback(async () => {
    const id = Number(params.id)
    if (id) {
      try {
        const res = await getBoardRecordRoom(id)
        if (res.data.boardUuid) {
          const boardApp = await createFastboard({
            sdkConfig: {
              appIdentifier: BOARD_APP_ID,
              region: 'cn-hz',
            },
            joinRoom: {
              uid: `${screenUidRef.current}`,
              uuid: res.data.boardUuid,
              roomToken: res.data.boardRoomToken,
              isWritable: false,
            },
            managerConfig: {
              overwriteStyles:
                '.netless-window-manager-wrapper { background: #fff }',
            },
          })
          const moundDiv = document.getElementById('board')
          if (moundDiv) {
            createUI(boardApp, moundDiv)
          }
        } else {
          setTimeout(() => {
            initBoard()
          }, 2000)
        }
      } catch (error: unknown) {
        console.error(error)
      }
    }
  }, [params.id])

  useEffect(() => {
    initBoard()
  }, [initBoard])

  const intervalRef = useRef<NodeJS.Timeout>()
  const [classTiktokTime, setClassTiktokTime] = useState(0)
  const startTikTok = useCallback((time: number) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    setClassTiktokTime(+dayjs() - time)
    intervalRef.current = setInterval(() => {
      setClassTiktokTime(+dayjs() - time)
    }, 1000)
  }, [])
  const classTiktokTimeText = useMemo(() => {
    if (classTiktokTime) {
      const sec = `0${Math.floor((classTiktokTime / 1000) % 60)}`.slice(-2)
      const min = Math.floor(classTiktokTime / 1000 / 60)

      return `Started: ${min}:${sec}`
    } else {
      return '--:--'
    }
  }, [classTiktokTime])

  const initClassroomTime = useCallback(() => {
    if (params.id) {
      getClassroomTime(Number(params.id)).then((res) => {
        const classStartedAt = res.data.classStartedAt
        if (classStartedAt) {
          startTikTok(classStartedAt)
        } else {
          setTimeout(() => {
            initClassroomTime()
          }, 1500)
        }
      })
    }
  }, [params.id, startTikTok])
  useEffect(() => {
    initClassroomTime()
  }, [initClassroomTime])

  return (
    <Container>
      <div className="top">
        <div className="title">
          <div>{`${teacherName} 's room`} </div>
        </div>
        <div className="top-middle">
          <div className="time">{classTiktokTimeText}</div>
        </div>
        <div className="top-right"></div>
      </div>
      <div className="bottom">
        <div className="left">
          <div className="show-area" id="board"></div>
          <div className="show-area" id="screen-video"></div>
        </div>
        <div className="right">
          <div id="camera-video" className="video-wrap local">
            <div className="mask">{teacherName}</div>
          </div>
          <div id="remote-video" className="video-wrap remote">
            <div className="mask">{studentName}</div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Index
