import { Button, Form, Input, message } from 'antd'
import { useCallback } from 'react'

import { changePassword } from '~/services/teacher'

import { Container } from './style'

// import { postChangePassword } from '~/services/agent'

const Index = () => {
  const onFinish = useCallback(
    (value: { password: string; oldPassword: string }) => {
      changePassword(value.oldPassword, value.password)
        .then(() => {
          message.success('success')
        })
        .catch((err) => {
          message.error(err?.response?.data || err.message || 'error')
        })
    },
    [],
  )
  return (
    <Container>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="oldPassword"
          name="oldPassword"
          rules={[{ required: true, message: 'Please input oldPassword' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="newPassword"
          name="password"
          rules={[{ required: true, message: 'Please Input newPassword' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Confirm Change
          </Button>
        </Form.Item>
      </Form>
    </Container>
  )
}

export default Index
