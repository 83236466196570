import http from '~/utils/http'

export function postClassroomCallback(roomUuid: string) {
  return http.post(
    '/classroom/callback/create',
    {},
    {
      params: {
        roomUuid,
      },
    },
  )
}

export function getClassroomTime(id: number) {
  return http.get(`/class-record/appointment/${id}`)
}

export function patchClassroomTime(id: number) {
  return http.patch(`/class-record/appointment/${id}`)
}

export function getRecordClassroomDetail(id: number) {
  return http.get(`/classroom/agora/recorder/${id}`)
}
