import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  box-sizing: border-box;
  > .top {
    height: 45px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    > .title {
      > .title-text {
        font-weight: bold;
      }
    }
    > .top-right {
      display: flex;
      align-items: center;
      font-size: 20px;
      > .icon {
        padding: 0 14px;
        cursor: pointer;
        &:last-of-type {
          padding-right: 0;
        }
      }
      > .share-btn {
        cursor: pointer;
      }
    }
    > .top-middle {
      display: flex;
      align-items: center;
      color: #ccc;
      > .time {
        font-weight: normal;
        &.timeout {
          color: #ff2442;
          font-weight: bold;
        }
      }
      > .btn {
        margin-left: 12px;
      }
    }
  }
  > .bottom {
    height: calc(100vh - 45px);
    display: flex;
    > .left {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      flex: 0 0 85%;
      background-color: rgba(0, 0, 0, 0.01);
    }
    > .right {
      flex: 0 0 15%;
      > .video-wrap {
        background-color: rgba(0, 0, 0, 0.05);
        height: 200px;
        position: relative;
        .mask {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 20px;
          line-height: 20px;
          background-color: rgba(0, 0, 0, 0.1);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 10px;
          color: #eee;
          font-weight: bold;
          z-index: 100;
        }
        &.remote {
          margin-top: 6px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`
