import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;
  > .top {
  }
  > .table {
    margin-top: 20px;
  }
`

export const ModalFormContainer = styled.div``
