import { createBrowserRouter, redirect } from 'react-router-dom'

import Layout from '~/components/layouts'
import { TOKEN_KEY } from '~/constants/storage'
import Page404 from '~/pages/404'
import Login from '~/pages/login'
import MyAppointment from '~/pages/my-appointment'
import MySchedule from '~/pages/my-schedule'
import Overview from '~/pages/overview'
import Password from '~/pages/password'
import Record from '~/pages/record'
import RtcClassroom from '~/pages/rtc-classroom'
import RecordClassroom from '~/pages/record-classroom'

function isLogin() {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    return true
  } else {
    return false
  }
}
export default createBrowserRouter([
  {
    path: '/',
    loader: () => {
      if (isLogin()) {
        return redirect('/home/overview')
      } else {
        return redirect('/login')
      }
    },
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/home',
    element: <Layout />,
    loader: () => {
      if (isLogin()) {
        return true
      } else {
        return redirect('/login')
      }
    },
    children: [
      {
        path: 'overview',
        element: <Overview />,
      },
      {
        path: 'my-schedule',
        element: <MySchedule />,
      },
      {
        path: 'my-appointment',
        element: <MyAppointment />,
      },
      {
        path: 'password',
        element: <Password />,
      },
    ],
  },
  {
    path: '/record-classroom/:id',
    element: <RecordClassroom />,
  },
  {
    path: '/rtc_classroom/:id',
    element: <RtcClassroom />,
    loader: () => {
      if (isLogin()) {
        return true
      } else {
        return redirect('/login')
      }
    },
  },
  {
    path: '/record',
    element: <Record />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
])
