import { message } from 'antd'
import axios from 'axios'

import httpConfig from '~/config/http'
import { TOKEN_KEY } from '~/constants/storage'

const http = axios.create(httpConfig)

http.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_KEY)

  config.headers.Authorization = token
  return config
})

http.interceptors.response.use(undefined, (err) => {
  console.log(err)
  if (err.status === 401) {
    message.warning('Your login has expired. Please log in again.')
    localStorage.removeItem(TOKEN_KEY)
    setTimeout(() => {
      location.reload()
    }, 1000)
    return Promise.reject(err)
  }
  return Promise.reject(err)
})

export default http
