import {
  Button,
  Divider,
  Popconfirm,
  Space,
  Table,
  TableProps,
  Typography,
  message,
} from 'antd'
import dayjs from 'dayjs'
import { memo, useCallback, useEffect, useRef, useState } from 'react'

import { cancelAppointment, getAppointment } from '~/services/appointment'
import { IAppointment } from '~/types/appointment'
import { IStudent } from '~/types/student'

import { Container } from './style'

const Index = () => {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<IAppointment[]>([])

  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)

  const onPageChange = useCallback((cPage: number, cSize: number) => {
    setPage(cPage)
    setSize(cSize)
  }, [])

  const fetchData = useCallback(() => {
    setLoading(true)
    getAppointment(page, size)
      .then((res) => {
        setTotal(res.data.total)
        setDataSource(res.data?.content || [])
      })
      .catch((err) => {
        message.error(err?.response?.data || err?.message || 'error request')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [page, size])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onSearch = useCallback(() => {
    fetchData()
  }, [fetchData])

  const columns = useRef<TableProps<IAppointment>['columns']>([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      fixed: 'left',
    },

    {
      title: 'Student Info',
      dataIndex: 'student',
      key: 'student',
      render: (student: IStudent) => {
        if (!student) {
          return '-'
        }
        return (
          <div>
            <div>ID: {student.id}</div>
            <div>NAME：{student.name}</div>
            <div>TEL：{student.phone}</div>
            <div>EMAIL：{student.email}</div>
          </div>
        )
      },
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Schedule Info',
      dataIndex: 'scheduleId',
      key: 'scheduleId',
      width: 180,
      render: (_, record) => {
        if (record.schedule) {
          return (
            <div>
              <div>
                {dayjs(record.schedule.from).format('DD/MM/YYYY HH:mm:ss')}
              </div>
              <div style={{ textAlign: 'center' }}>to</div>
              <div>
                {dayjs(record.schedule.to).format('DD/MM/YYYY HH:mm:ss')}
              </div>
            </div>
          )
        }
        return '-'
      },
    },
    {
      title: 'Textbook',
      dataIndex: 'textbookId',
      key: 'textbookId',
      width: 150,
      render: (text, record) => (
        <Typography.Link target="_blank" href={record.textbook.downloadUrl}>
          Preview Textbook
        </Typography.Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
    },

    {
      title: 'Cancel Time',
      dataIndex: 'cancelTime',
      key: 'cancelTime',
      width: 150,
    },
    {
      title: 'Cancel Reason',
      dataIndex: 'cancelReason',
      key: 'cancelReason',
      width: 150,
    },
    {
      title: 'Finish Time',
      dataIndex: 'completedTime',
      key: 'completedTime',
      width: 150,
    },

    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 150,
    },
    {
      title: 'ClassRoom Url',
      dataIndex: 'classRoomUrl',
      key: 'classRoomUrl',
      width: 150,
    },
    {
      title: 'Update At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => dayjs(text).format('DD/MM/YYYY HH:mm:ss'),
      width: 180,
    },
    {
      title: 'Create At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text).format('DD/MM/YYYY HH:mm:ss'),
      width: 150,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        if (record.status === 'CANCELED') {
          return null
        }
        return (
          <Space>
            <Popconfirm
              title="Cancel this schedule?"
              onConfirm={() => {
                cancelAppointment(record.id)
                  .then(() => {
                    message.success('success~')
                    onSearch()
                  })
                  .catch((err) => {
                    message.error(err?.response?.data || err.message)
                  })
              }}
            >
              <Typography.Link onClick={() => {}}>Cancel</Typography.Link>
            </Popconfirm>
            <Divider style={{ marginInline: 0 }} type="vertical" />
            <Typography.Link
              onClick={() => {
                const { roomUuid, rtmToken, schedule, id } = record
                const now = +dayjs()
                if (
                  now < schedule.from - 5 * 60 * 1000 &&
                  import.meta.env.MODE !== 'development' &&
                  window.location.origin.indexOf('beta') === -1
                ) {
                  message.warning(
                    'You can enter the classroom 5 minutes before class time.',
                  )
                  return
                }
                if (now > schedule.to) {
                  message.warning('Class time is over')
                  return
                }
                let duration = schedule.to - schedule.from
                duration = duration > 86400 ? 86400 : duration

                if (!duration) {
                  message.warning('Invalid Schedule Time')
                  return
                }

                if (roomUuid && rtmToken) {
                  window.open(`/rtc_classroom/${id}`)
                }
              }}
            >
              go to room
            </Typography.Link>
          </Space>
        )
      },
      fixed: 'right',
      width: 200,
      align: 'center',
    },
  ])

  return (
    <Container>
      <div className="top">
        <Button
          style={{ marginRight: 12 }}
          type="primary"
          loading={loading}
          onClick={onSearch}
        >
          query
        </Button>
      </div>
      <div className="table">
        <Table
          scroll={{ x: 2000, y: 500 }}
          rowKey="id"
          loading={loading}
          columns={columns.current}
          dataSource={dataSource}
          pagination={{
            total,
            pageSize: size,
            current: page,
            onChange: onPageChange,
          }}
        />
      </div>
      <div className="pagination"></div>
    </Container>
  )
}

export default memo(Index)
