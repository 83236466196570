import styled from 'styled-components'

export const Container = styled.div`
  background-color: white;
  > .top {
    height: 56px;
    background-color: #002329;
    display: flex;
    align-items: center;
    padding-left: 24px;
    justify-content: space-between;
    > .title {
      color: #fff;
      font-weight: bold;
    }
    > .right {
      .button {
        color: #fff;
      }
    }
  }
  > .bottom {
    height: calc(100vh - 56px);
    display: flex;
    > .left {
      width: 260px;
      overflow: auto;
      .menu {
        color: #333;
      }
    }
    > .right {
      flex: 1;
      padding: 36px 24px 0;
      background-color: #eee;
      box-sizing: border-box;
      max-width: calc(100vw - 260px);
      overflow: hidden;
      > .content {
        background-color: #fff;
        border-radius: 8px;
        height: calc(100vh - 56px - 36px - 36px);
        overflow-y: auto;
      }
      > .copyright {
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: rgb(153, 153, 153);
      }
    }
  }
`
