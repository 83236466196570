import styled from 'styled-components'

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.01);
  position: relative;
  > .animation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .top {
    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    box-shadow: rgb(237, 240, 247) 0px -1px 0px inset;
    .content {
      height: 72px;
      display: flex;
      align-items: center;
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 56px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        color: rgba(45, 45, 45);
        font-size: 20px;
        background-color: #bae0ff;
        background-image: url('https://7161-qa-7gcr3atfad7fb691-1329511088.tcb.qcloud.la/fe/logo.png?sign=5b370815f1a95081f480e8f243a3bfb5&t=1728483112');
        background-size: cover;
        background-position: center center;
      }
      .title {
        color: rgba(45, 45, 45);
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .middle {
    position: relative;
    height: calc(100vh - 72px - 32px);
    padding-right: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .login-wrap {
      padding: 40px 32px 20px 32px;
      width: 320px;
      border-radius: 16px;
      background-color: white;
      position: relative;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      .title {
        text-align: center;
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }
      .form {
        margin-top: 28px;
      }
    }
  }
  .bottom {
    position: relative;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: rgb(153, 153, 153);
  }
`
