import { Button, Form, Input, message } from 'antd'
import type { FormProps } from 'antd'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { TOKEN_KEY } from '~/constants/storage'
import { login } from '~/services/login'

import Animation from './Animation'
import { Container } from './style'
import './style.css'

const Index = () => {
  const navigate = useNavigate()
  const onFinish = useCallback<
    Required<FormProps<{ username: string; password: string }>>['onFinish']
  >(
    (value) => {
      login(value.username, value.password)
        .then((res) => {
          localStorage.setItem(TOKEN_KEY, `Bearer ${res.data.token}`)
          message.success('login successful~')
          navigate('/home/overview')
        })
        .catch((err) => {
          message.error(err?.response?.data || err?.message || '登录失败')
        })
    },
    [navigate],
  )

  return (
    <Container>
      <div className="animation">
        <Animation />
      </div>
      <div className="top">
        <div className="content">
          <div className="icon"></div>
          <div className="title">Shaokai-Chasing Tutor</div>
        </div>
      </div>
      <div className="middle">
        <div className="login-wrap">
          <div className="title">Tutor Login</div>
          <div className="form">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <Form.Item
                label="username"
                name="username"
                rules={[{ required: true, message: 'please input username' }]}
                // initialValue="john.doe@example.com"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="password"
                name="password"
                rules={[{ required: true, message: 'please input password' }]}
                // initialValue="A1XZxWVoQL0LLioK"
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className="bottom">Shaokai Copyright © 2024</div>
    </Container>
  )
}

export default Index
