import { SmileOutlined } from '@ant-design/icons'
import {
  Button,
  Calendar,
  Result,
  Spin,
  Switch,
  TimePicker,
  message,
} from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'

import { getScheduleByDay, updateSchedule } from '~/services/teacher-schedule'
import { ISchedule } from '~/types/teacher-schedule'

import { Container, RightContainer } from './style'

const Index = () => {
  const [loading, setLoading] = useState(false)
  const currentScheduleId = useRef(0)
  const [currentDay, setCurrentDay] = useState(dayjs())
  const [saveLoading, setSaveLoading] = useState(false)
  const [currentScheduleList, setCurrentScheduleList] = useState<ISchedule[]>(
    [],
  )
  const deletedIdsRef = useRef<number[]>([])

  const initScheduleByDay = useCallback(() => {
    setLoading(true)
    getScheduleByDay(+currentDay.hour(0).minute(0).millisecond(0).second(0))
      .then((res) => {
        deletedIdsRef.current = []
        setCurrentScheduleList(res?.data || [])
      })
      .catch(() => {
        currentScheduleId.current = 0
        setCurrentScheduleList([])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currentDay])

  useEffect(() => {
    initScheduleByDay()
  }, [initScheduleByDay])

  /** 选择日期 */
  const onSelect = useCallback((value: Dayjs) => {
    setCurrentScheduleList([])
    setCurrentDay(value)
  }, [])

  /** 保存 */
  const saveHandler = useCallback(() => {
    console.log(currentScheduleList)
    if (currentScheduleList.some((item) => item.from > item.to)) {
      message.warning(
        'Please make sure that the end date is later than the start date.',
      )
      return
    }
    for (let i = 0; i < currentScheduleList.length; i++) {
      const schedule = currentScheduleList[i]
      if (schedule.from < 1577836800000) {
        message.error(
          `The number ${i} schedule is set incorrectly. Please remove it and set it up again.`,
        )
        return
      }
    }
    setSaveLoading(true)
    updateSchedule(currentScheduleList, deletedIdsRef.current)
      .then(() => {
        message.success('success')
        initScheduleByDay()
      })
      .catch((err) => {
        message.error(err?.response?.data || err?.message)
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }, [currentScheduleList, initScheduleByDay])

  const addHandler = useCallback(() => {
    if (+currentDay) {
      setCurrentScheduleList((pre) => {
        return [
          ...pre,
          {
            from: +currentDay.second(0).minute(0),
            to: +currentDay.second(0).minute(0) + 25 * 60 * 1000,
            enabled: true,
            taken: false,
          },
        ]
      })
    }
  }, [currentDay])

  return (
    <Container>
      <div className="content">
        <div className="left">
          <Calendar fullscreen={false} value={currentDay} onSelect={onSelect} />
        </div>
        <div className="divider"></div>
        <RightContainer>
          {loading && (
            <div className="loading-wrap">
              <Spin />
            </div>
          )}
          <div>
            <Button
              style={{ marginRight: 12 }}
              type="primary"
              onClick={saveHandler}
              loading={saveLoading}
            >
              Save
            </Button>
            <Button onClick={addHandler}>Add Schedule</Button>
          </div>
          {currentScheduleList.length === 0 ? (
            <div className="empty">
              <Result
                icon={<SmileOutlined />}
                title="There is no any schedules."
              />
            </div>
          ) : (
            <div className="list">
              {currentScheduleList.map((item, index) => {
                return (
                  <div className="card" key={index}>
                    <div className="line">
                      <div className="label">From: </div>
                      <div className="value">
                        <TimePicker
                          size="small"
                          value={dayjs(item.from)}
                          format="HH:mm"
                          minuteStep={30}
                          onChange={(value) => {
                            setCurrentScheduleList((pre) => {
                              return pre.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    from: +value,
                                    to: +value + 25 * 60 * 1000,
                                  }
                                }
                                return item
                              })
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="line">
                      <div className="label">To: </div>
                      <div className="value">
                        <TimePicker
                          size="small"
                          format="HH:mm"
                          disabled
                          minuteStep={30}
                          value={dayjs(item.to)}
                        />
                      </div>
                    </div>
                    <div className="line">
                      <div className="label">Enable: </div>
                      <div className="value">
                        <Switch
                          size="small"
                          value={item.enabled}
                          onChange={(value) => {
                            setCurrentScheduleList((pre) => {
                              return pre.map((item, i) => {
                                if (i === index) {
                                  return {
                                    ...item,
                                    enabled: value,
                                  }
                                }
                                return item
                              })
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className="line">
                      <div className="label">Ordered: </div>
                      <div className="value">
                        <Switch size="small" value={item.taken} disabled />
                      </div>
                    </div>
                    {!item.taken && (
                      <div className="del-button">
                        <Button
                          color="danger"
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            setCurrentScheduleList((pre) => {
                              const deleteItem = pre.splice(index, 1)[0]
                              if (deleteItem.id) {
                                deletedIdsRef.current.push(deleteItem.id)
                              }
                              return [...pre]
                            })
                          }}
                        >
                          Delete Schedule
                        </Button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </RightContainer>
      </div>
    </Container>
  )
}

export default Index
