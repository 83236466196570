import { nanoid } from 'nanoid'
import { useEffect, useRef } from 'react'

import { APP_ID } from '~/constants/agora'

import { Container } from './style'

function getParams(location: Location) {
  const urlParams = new URLSearchParams(location.href.split('?').pop())
  const audienceParams: any = {}
  // @ts-expect-error any
  for (const key of urlParams.keys()) {
    audienceParams[key] = urlParams.get(key)
  }
  return audienceParams
}

const Index = () => {
  const randomId = useRef(nanoid(24))
  useEffect(() => {
    const {
      userUuid,
      userName,
      roomUuid,
      roleType,
      roomType,
      roomName,
      // pretest,
      rtmUid,
      rtmToken,
      language,
      startTime,
      duration,
      recordUrl,
      appId,
      userRole,
      // translateLanguage,
      region,
      minFPS = 1,
      maxFPS = 5,
      resolution = 0.8,
    } = getParams(window.location)

    const recordOptions = {
      minFPS,
      maxFPS,
      resolution,
      autoResolution: false,
      autoFPS: false,
      maxResolutionLevel: 1,
      forceCanvas: true,
    }

    window.AgoraEduSDK.config({
      appId: APP_ID,
      cachePath: undefined,
      region: region ? region : 'CN',
    })

    window.AgoraEduSDK.launch(document.getElementById(randomId.current), {
      userUuid,
      userName,
      roomUuid,
      roleType: Number(roleType),
      roomType: Number(roomType),
      roomName,
      pretest: false,
      rtmUid,
      rtmToken,
      language,
      startTime,
      duration,
      recordUrl,
      appId,
      userRole,
      courseWareList: [],
      recordOptions,
      widgets: {
        // popupQuiz: AgoraSelector,
        // countdownTimer: AgoraCountdown,
        // easemobIM: AgoraHXChatWidget,
        // mediaPlayer: FcrStreamMediaPlayerWidget,
        // poll: AgoraPolling,
        // watermark: FcrWatermarkWidget,
        // webView: FcrWebviewWidget,
        // netlessBoard: FcrBoardWidget,
      },
      listener: (evt: any, args: any) => {
        console.log('connected to classroom', evt, args)
        if (evt === 1) {
          // do something when classroom is connected
          window.AgoraEduSDK.setRecordReady()
        }
      },
    })
  }, [])

  return <Container id={randomId.current}></Container>
}

export default Index
