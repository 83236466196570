import { ISchedule } from '~/types/teacher-schedule'
import http from '~/utils/http'

export function getScheduleByDay(day: number) {
  return http.get(`/teacher/schedule/${day}`)
}

export function updateSchedule(schedules: ISchedule[], deletedIds: number[]) {
  return http.patch('/teacher/schedule', {
    schedules,
    deletedIds,
  })
}
