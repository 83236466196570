import styled from 'styled-components'

export const Container = styled.div`
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333;
  .welcome {
    margin-right: 12px;
  }
`
